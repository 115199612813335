// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-parents-js": () => import("./../src/pages/parents.js" /* webpackChunkName: "component---src-pages-parents-js" */),
  "component---src-pages-past-litters-js": () => import("./../src/pages/past-litters.js" /* webpackChunkName: "component---src-pages-past-litters-js" */),
  "component---src-pages-process-js": () => import("./../src/pages/process.js" /* webpackChunkName: "component---src-pages-process-js" */),
  "component---src-pages-puppies-js": () => import("./../src/pages/puppies.js" /* webpackChunkName: "component---src-pages-puppies-js" */),
  "component---src-templates-puppy-template-js": () => import("./../src/templates/PuppyTemplate.js" /* webpackChunkName: "component---src-templates-puppy-template-js" */)
}

